import React from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { getMapDisplayName, getWinRateColor } from "@/game-val/utils.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { calcRate } from "@/util/helpers.mjs";

const MapTooltip = styled("div")`
  min-width: calc(var(--sp-1) * 40);
  text-transform: capitalize;

  .stat-line {
    display: flex;
    justify-content: space-between;
  }
  .stat-line-label {
    color: var(--shade2);
  }
  .round-result {
    font-size: var(--sp-3);
    font-weight: 700;
  }
  .loss {
    color: #ff5757;
  }
  .win {
    color: #47b3ff;
  }

  .percent-stat {
    color: var(--shade3);
  }
`;

const getMapTip = (
  t,
  mapName,
  wins,
  winPercent,
  losses,
  lossPercent,
  attackWinPercent,
  attackingWon,
  attackingPlayed,
  defendingWon,
  defendingPlayed,
  defenseWinPercent,
) => {
  return (
    <MapTooltip>
      <p className="type-h6">
        {t("val:mapName", "{{mapName}}", {
          mapName: mapName,
        })}
      </p>

      <div className="stat-line">
        <p className="type-caption">{t("val:wins", "Wins:")}</p>
        <div>
          <span className="round-result win">{wins}</span>
          &nbsp;
          <span className="type-caption--bold percent-stat">
            (
            {t("common:percent", "{{percent, percent}}", {
              percent: winPercent,
            })}
            )
          </span>
        </div>
      </div>

      <div className="stat-line">
        <p className="type-caption">{t("val:losses", "Losses:")}</p>
        <div>
          <span className="round-result loss">{losses}</span>
          &nbsp;
          <span className="type-caption--bold percent-stat">
            (
            {t("common:percent", "{{percent, percent}}", {
              percent: lossPercent,
            })}
            )
          </span>
        </div>
      </div>

      <p
        className="type-body2-form--active"
        style={{ paddingTop: "var(--sp-2)" }}
      >
        {t("val:attackingRounds", "Attacking Rounds:")}
      </p>
      <div className="stat-line">
        <p className="type-caption">{t("val:wins", "Wins:")}</p>
        <div>
          <span className="round-result win">{attackingWon}</span>
          &nbsp;
          <span className="type-caption--bold percent-stat">
            (
            {t("common:percent", "{{percent, percent}}", {
              percent: attackWinPercent,
            })}
            )
          </span>
        </div>
      </div>

      <div className="stat-line">
        <p className="type-caption">{t("val:losses", "Losses:")}</p>
        <div>
          <span className="round-result loss">
            {attackingPlayed - attackingWon}
          </span>
          &nbsp;
          <span className="type-caption--bold percent-stat">
            (
            {t("common:percent", "{{percent, percent}}", {
              percent: 1 - attackWinPercent,
            })}
            )
          </span>
        </div>
      </div>

      <p
        className="type-body2-form--active"
        style={{ paddingTop: "var(--sp-2)" }}
      >
        {t("val:defendingRounds", "Defending Rounds:")}
      </p>
      <div className="stat-line">
        <p className="type-caption">{t("val:wins", "Wins:")}</p>
        <div>
          <span className="round-result win">{defendingWon}</span>
          &nbsp;
          <span className="type-caption--bold percent-stat">
            (
            {t("common:percent", "{{percent, percent}}", {
              percent: defenseWinPercent,
            })}
            )
          </span>
        </div>
      </div>
      <div className="stat-line">
        <p className="type-caption">{t("val:losses", "Losses:")}</p>
        <div>
          <span className="round-result loss">
            {defendingPlayed - defendingWon}
          </span>
          &nbsp;
          <span className="type-caption--bold percent-stat">
            (
            {t("common:percent", "{{percent, percent}}", {
              percent: defenseWinPercent,
            })}
            )
          </span>
        </div>
      </div>
    </MapTooltip>
  );
};

const getMapList = (mapData, mapMeta) => {
  const metaDataArray = Object.values(mapMeta);
  const mapList = mapData.map((data) => {
    const mapKey = getMapDisplayName(data.gameMap.name)?.toLowerCase();
    return { meta: metaDataArray.find((meta) => meta.key === mapKey), data };
  });
  return mapList.sort((a, b) => b.data.matchesWon - a.data.matchesWon);
};

const MapStats = ({ mapStats, mapMeta, isDeathmatch }) => {
  const { t } = useTranslation();

  return (
    <ListItemsTruncated
      title={t("common:maps", "Maps")}
      list={mapStats && mapMeta ? getMapList(mapStats, mapMeta) : []}
      itemLeftContent={(item) => {
        const mapName = item?.meta?.name;
        const mapData = item?.data || {};
        const {
          matchesPlayed: matches,
          matchesWon: wins,
          matchesLost: losses,
        } = mapData;
        const ties = matches - wins - losses;
        const map_image = item?.meta?.images?.hero;
        if (!matches) return null;
        const winPercent = calcRate(wins, matches - (ties || 0), 2);

        return (
          <>
            <img
              width="71"
              height="40"
              src={map_image}
              loading="lazy"
              alt={mapName}
            />
            <div>
              <div className="type-callout--bold map-name">
                {t("val:mapName", "{{mapName}}", {
                  mapName: mapName,
                })}
              </div>
              <div className="type-caption--semi">
                <span
                  className="match-sub-stat"
                  style={{ color: getWinRateColor(winPercent) }}
                >
                  {t("common:percent", "{{percent, percent}}", {
                    percent: winPercent,
                  })}
                  &nbsp;
                </span>
                <span className="map-win-loss">
                  {t("common:stats.winsDashLosses", `{{wins}}W - {{losses}}L`, {
                    wins: wins,
                    losses: losses,
                  })}
                </span>
              </div>
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        if (isDeathmatch) return null;
        const mapData = item?.data || {};
        const {
          atkRoundsWon,
          atkRoundsLost,
          defRoundsWon,
          defRoundsLost,
          matchesPlayed,
        } = mapData;
        if (!matchesPlayed) return null;
        const attackingPlayed = atkRoundsWon + atkRoundsLost;
        const defendingPlayed = defRoundsWon + defRoundsLost;
        const defenseWinPercent = calcRate(defRoundsWon, defendingPlayed, 2);
        const attackWinPercent = calcRate(atkRoundsWon, attackingPlayed, 2);

        return (
          <>
            <p
              className="type-caption--bold"
              style={{ color: getWinRateColor(attackWinPercent) }}
            >
              <span className="map-win-loss">{t("val:atk", "Atk")}</span>&nbsp;
              <span>
                {t("common:percent", "{{percent, percent}}", {
                  percent: attackWinPercent,
                })}
              </span>
            </p>
            <p
              className="type-caption--bold"
              style={{ color: getWinRateColor(defenseWinPercent) }}
            >
              <span className="map-win-loss">{t("val:def", "Def")}</span>&nbsp;
              <span>
                {t("common:percent", "{{percent, percent}}", {
                  percent: defenseWinPercent,
                })}
              </span>
            </p>
          </>
        );
      }}
      itemTooltip={(item) => {
        const mapName = item?.meta?.name;
        const mapData = item?.data;
        const {
          atkRoundsWon,
          atkRoundsLost,
          defRoundsWon,
          defRoundsLost,
          matchesPlayed,
          matchesWon,
          matchesLost,
        } = mapData;

        if (!matchesPlayed) return null;

        const ties = matchesPlayed - matchesWon - matchesLost;
        const attackingPlayed = atkRoundsWon + atkRoundsLost;
        const defendingPlayed = defRoundsWon + defRoundsLost;
        const winPercent = calcRate(matchesWon, matchesPlayed - (ties || 0), 2);
        const lossPercent = calcRate(
          matchesLost,
          matchesPlayed - (ties || 0),
          2,
        );
        const defenseWinPercent = calcRate(defRoundsWon, defendingPlayed, 2);
        const attackWinPercent = calcRate(atkRoundsWon, attackingPlayed, 2);
        return ReactDOMServer.renderToStaticMarkup(
          getMapTip(
            t,
            mapName,
            matchesPlayed,
            winPercent,
            matchesLost,
            lossPercent,
            attackWinPercent,
            atkRoundsWon,
            attackingPlayed,
            defRoundsWon,
            defendingPlayed,
            defenseWinPercent,
          ),
        );
      }}
    />
  );
};

export default MapStats;
