import { css, keyframes, styled } from "goober";

export const WIN_COLOR = "#FF9417";
const DEFEAT_COLOR = "#49B4FF";

/**
 * Animations
 */
const animTRBorder = (val) => keyframes`
 0% {
   width: 0;
   height: 0;
   border-top-color: ${val};
   border-right-color: transparent;
   border-bottom-color: transparent;
   border-left-color: transparent;
 }

 50% {
   width: 100%;
   height: 0;
   border-top-color: ${val};
   border-right-color: ${val};
   border-bottom-color: transparent;
   border-left-color: transparent;
 }

 100% {
   width: 100%;
   height: 100%;
   border-top-color: ${val};
   border-right-color: ${val};
   border-bottom-color: transparent;
   border-left-color: transparent;
 }
`;

const animLBBorder = (val) => keyframes`
 0% {
   width: 0;
   height: 0;
   border-top-color: transparent;
   border-right-color: transparent;
   border-bottom-color: transparent;
   border-left-color: ${val};
 }

 50% {
   width: 0;
   height: 100%;
   border-top-color: transparent;
   border-right-color: transparent;
   border-bottom-color: ${val};
   border-left-color: ${val};
 }

 100% {
   width: 100%;
   height: 100%;
   border-top-color: transparent;
   border-right-color: transparent;
   border-bottom-color: ${val};
   border-left-color: ${val};
 }
`;

const animFadeIn = () => keyframes`
 0% {
   opacity: 0;
 }

 60% {
   opacity: 0;
 }

 100% {
   opacity: 1;
 }
`;

const animFlareUp = () => keyframes`
 0% {
   transform: translateY(0px) scale(1);
   opacity: 1;
   filter: blur(0px);
 }

 75% {
   opacity: 1;
 }

 100% {
   transform: translateY(-30px) scale(1.4);
   filter: blur(4px);
   margin-left: 0px;
   opacity: 0;
 }
`;

const animFlareDown = () => keyframes`
 0% {
   transform: translateY(32px) scale(1);
   opacity: 1;
   filter: blur(0px);
 }

 75% {
   opacity: 1;
 }

 100% {
   transform: translateY(60px) scale(1.4);
   filter: blur(4px);
   margin-left: 0px;
   opacity: 0;
 }
`;

/**
 * Styles
 */
export const BadgeContainer = styled("div")`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  mix-blend-mode: normal;
  backdrop-filter: blur(4px);
  border-radius: var(--br-sm);
  padding: var(--sp-0_5) var(--sp-1_5);
  margin-left: var(--sp-1);
  cursor: pointer;

  ::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 0;
    height: 0;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 4px;
  }

  ::after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 0;
    height: 0;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 4px;
  }

  &.win {
    &::before {
      width: 100%;
      height: 100%;
      border-color: ${WIN_COLOR};
      animation: ${animTRBorder(WIN_COLOR)} 1s linear forwards;
    }
    &::after {
      width: 100%;
      height: 100%;
      border-color: ${WIN_COLOR};
      animation: ${animLBBorder(WIN_COLOR)} 1s linear forwards;
    }
    span {
      background: ${WIN_COLOR};
      animation: ${animFlareUp} 1s ease-in-out forwards;
      animation-iteration-count: 3;
    }
    div {
      background: ${WIN_COLOR};
    }
  }
  &.loss {
    &::before {
      width: 100%;
      height: 100%;
      border-color: ${DEFEAT_COLOR};
      animation: ${animTRBorder(DEFEAT_COLOR)} 1s linear forwards;
    }
    &::after {
      width: 100%;
      height: 100%;
      border-color: ${DEFEAT_COLOR};
      animation: ${animLBBorder(DEFEAT_COLOR)} 1s linear forwards;
    }
    span {
      background: ${DEFEAT_COLOR};
      animation: ${animFlareDown} 1s ease-in-out forwards;
      animation-iteration-count: 3;
    }
    div {
      background: ${DEFEAT_COLOR};
    }
  }

  span {
    position: absolute;
    display: block;
    border: 5px;
    border-radius: 50%;
  }

  div {
    mix-blend-mode: screen;
    filter: blur(20px);
    width: 60px;
    height: var(--sp-5);
    position: absolute;
    animation: ${animFadeIn} 1s ease-in-out forwards;
  }

  span.bub-1 {
    width: 3px;
    height: 3px;
    left: 5px;
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0s;
    animation-duration: 1s;
  }
  span.bub-2 {
    width: 3px;
    height: 3px;
    left: 9px;
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.5s;
    animation-duration: 1s;
  }
  span.bub-3 {
    width: 3px;
    height: 3px;
    left: 15px;
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 1s;
    animation-duration: 1s;
  }
  span.bub-4 {
    width: 3px;
    height: 3px;
    left: var(--sp-5);
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.3s;
    animation-duration: 1s;
  }

  span.bub-5 {
    width: 3px;
    height: 3px;
    left: var(--sp-6);
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.8s;
    animation-duration: 1s;
  }
  span.bub-6 {
    width: 3px;
    height: 3px;
    left: var(--sp-7_5);
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.5s;
    animation-duration: 1s;
  }
  span.bub-7 {
    width: 3px;
    height: 3px;
    left: var(--sp-9);
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.7s;
    animation-duration: 1s;
  }
  span.bub-8 {
    width: 3px;
    height: 3px;
    left: 40px;
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.2s;
    animation-duration: 1s;
  }
  span.bub-9 {
    width: 3px;
    height: 3px;
    left: 45px;
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.9s;
    animation-duration: 1s;
  }
  span.bub-10 {
    width: 3px;
    height: 3px;
    left: 50px;
    top: -3px;
    opacity: 0;

    filter: blur(1px);
    animation-delay: 0.3s;
    animation-duration: 1s;
  }
`;

export const cssTooltipContainerWithArrow = () => css`
  display: flex;
  align-items: baseline;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  position: absolute !important;
  z-index: 999999;
  color: #fff;
  background-color: var(--shade10);
  padding: var(--sp-2_5);
  border-radius: var(--br);
  font-size: var(--sp-3_5);
  font-family: "Inter", Arial, Helvetica, sans-serif;
  pointer-events: none;
  transform: translate(-45%, -85%);
  white-space: nowrap;
  filter: none !important;
  width: auto !important;
  height: auto !important;
  animation: none !important;

  &::after {
    content: " " !important;
    border: 8px solid var(--shade10) !important;
    position: absolute !important;
    top: 100% !important; /* At the bottom of the tooltip */
    left: 50% !important;
    border-left-width: 8px !important;
    border-right-width: 8px !important;
    border-bottom-width: 0px !important;
    /* transform: translate(50%, 0%)!important; */
    margin-left: -6px !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }
`;
