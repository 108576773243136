import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { TabsProps } from "clutch/src/Tabs/index.jsx";
import { Tabs } from "clutch/src/Tabs/index.jsx";
import type { ElementOf } from "ts-essentials";

import { ValorantConsoleData } from "@/game-val/constants/platform-constants.mjs";
import { useConsoleState } from "@/game-val/utils/console.mjs";
import {
  containerCss,
  tabContainerCss,
  tabLabelCss,
} from "@/shared/toggle-tabs.style.mjs";
import { useInteractionEvent } from "@/util/use-interaction-event.mjs";

type PlatformValue = ElementOf<typeof ValorantConsoleData>["key"];

const SELECT_PLATFORM = ["common:selectPlatform", "Select Platform"] as const;

interface ConsoleSelectorProps {
  placement?: string;
}

export default function ConsoleSelector({ placement }: ConsoleSelectorProps) {
  const sendEvent = useInteractionEvent("console-selector");
  const { consoleParam, setConsoleParam } = useConsoleState();
  const { t } = useTranslation();
  const tabOptions = useMemo(() => {
    return ValorantConsoleData.map<
      ElementOf<TabsProps<PlatformValue>["tabOptions"]>
    >((plat) => ({
      name: plat.key,
      content: (
        <div className="flex row justify-between gap-2 pad-0-3">
          <plat.icon className="toggle-icon" />
          <span className="toggle-description">{t(...plat.display)}</span>
        </div>
      ),
    }));
  }, [t]);

  const activeTab = useMemo(
    () => tabOptions.findIndex((v) => v.name === consoleParam),
    [consoleParam, tabOptions],
  );

  const onChange = useCallback(
    (idx: number) => {
      const option = tabOptions?.[idx];
      if (!option) return;
      sendEvent(
        "select-platform",
        {
          isConsole: option.name,
          placement,
        },
        "click",
      );
      setConsoleParam(option.name);
    },
    [setConsoleParam, tabOptions, sendEvent, placement],
  );

  return (
    <Tabs<PlatformValue>
      onChange={onChange}
      tabOptions={tabOptions}
      activeTab={activeTab}
      label={t(...SELECT_PLATFORM)}
      classes={{
        containerClass: containerCss(),
        tabContainerClass: tabContainerCss,
        tabLabelClass: tabLabelCss(),
      }}
    />
  );
}
