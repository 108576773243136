import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import type { ValorantContent } from "@/data-models/valorant-content.mjs";
import type { ValorantPlayerMatch } from "@/data-models/valorant-player-matches.mjs";
import type { ValorantProfile } from "@/data-models/valorant-profile.mjs";
import {
  getHighestNumPoints,
  getMatchPosition,
  getWinStatus,
} from "@/game-val/match-utils.mjs";
import {
  calcHeadshotPercent,
  getAgentImage,
  getQueueName,
  getWeaponImageByKey,
  isQueueCompetitive,
  isQueueDeathmatch,
  isQueueTeamDeathmatch,
} from "@/game-val/utils.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import globals from "@/util/global-whitelist.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

enum MatchTileTabsEnum {
  Scoreboard = "scoreboard",
  Statistics = "statistics",
  Hits = "hits",
  Weapons = "weapons",
  Abilities = "abilities",
}

const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-val/components/Scoreboard.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-val/components/StatsBreakdown.jsx"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-val/components/DamageBreakdown.jsx"),
  [MatchTileTabsEnum.Weapons]: () =>
    import("@/game-val/components/Weapons.jsx"),
  [MatchTileTabsEnum.Abilities]: () =>
    import("@/game-val/components/Abilities.jsx"),
};

const TabLabels = {
  [MatchTileTabsEnum.Scoreboard]: {
    name: ["val:matchtabs.scoreboard", "Scoreboard"] as Translation,
    shortName: ["val:matchtabs.scores", "Scores"] as Translation,
    key: MatchTileTabsEnum.Scoreboard,
  },
  [MatchTileTabsEnum.Statistics]: {
    name: ["common:navigation.statistics", "Statistics"] as Translation,
    shortName: ["val:matchtabs.statisticsShort", "Stats"] as Translation,
    key: MatchTileTabsEnum.Statistics,
  },
  [MatchTileTabsEnum.Weapons]: {
    name: ["common:stats.weapons", "Weapons"] as Translation,
    shortName: ["common:stats.weapons", "Weapons"] as Translation,
    key: MatchTileTabsEnum.Weapons,
  },
  [MatchTileTabsEnum.Hits]: {
    name: ["val:matchtabs.hits", "Hits"] as Translation,
    key: MatchTileTabsEnum.Hits,
  },
  [MatchTileTabsEnum.Abilities]: {
    name: ["val:matchtabs.abilities", "Abilities"] as Translation,
    key: MatchTileTabsEnum.Abilities,
  },
};

const PROFILE_MATCHTILE_TABS = (isDeathmatch, isTeamDeathmatch) => {
  if (isTeamDeathmatch) {
    return [
      TabLabels[MatchTileTabsEnum.Scoreboard],
      TabLabels[MatchTileTabsEnum.Statistics],
      TabLabels[MatchTileTabsEnum.Weapons],
      TabLabels[MatchTileTabsEnum.Hits],
    ];
  }

  if (isDeathmatch) {
    return [
      TabLabels[MatchTileTabsEnum.Scoreboard],
      TabLabels[MatchTileTabsEnum.Statistics],
    ];
  }

  return Object.values(TabLabels);
};

function BestWeapon({
  stats,
}: {
  stats: ValorantPlayerMatch["playerWeaponStats"];
}) {
  const {
    val: {
      content: { weapons },
    },
  } = useSnapshot(readState);
  const { t } = useTranslation();

  const bestWeaponStats = stats?.sort((a, b) => b.kills - a.kills)?.[0];
  const weaponId = bestWeaponStats?.weapon?.uuid;
  const weapon = weaponId
    ? weapons?.find((weapon) => weapon.uuid === weaponId?.replace("_alt", ""))
    : null;
  const weaponKey = weapon?.name?.toLowerCase()?.replaceAll(" ", "");

  if (!bestWeaponStats || !weapons || !weaponKey) return null;

  return (
    <div className="flex column justify-center align-center best-weapon">
      <img
        width="48"
        height="48"
        src={getWeaponImageByKey(weaponKey)}
        alt={weapon.name}
        style={{ width: "100%", height: "var(--sp-15)", objectFit: "cover" }}
      />
      <p className="color-shade2 type-caption">
        {t("common:stats.killsCount", "{{count, number}} Kills", {
          count: bestWeaponStats.kills,
        })}
      </p>
    </div>
  );
}

function MatchHistoryRow({
  match,
  allAgents,
  rating: rr,
  profileId,
  actId,
  matchId,
  profile,
  isConsole,
}: {
  match: ValorantPlayerMatch;
  allAgents: ValorantContent["agents"];
  rating: number;
  profileId: string;
  actId: string;
  matchId: string;
  profile: ValorantProfile;
  isConsole: boolean;
}) {
  const {
    t,
    // i18n: { language },
  } = useTranslation();
  const { state: routeState, searchParams, currentPath } = useRoute();

  const onExpand = useCallback(() => {
    updateRoute(currentPath, searchParams, {
      ...routeState,
      expandedMatchId: matchId,
    });
  }, [matchId, routeState, currentPath, searchParams]);

  if (!match) return null;

  const { queue, roundsPlayed, roundsWon, kills, deaths, assists, gameMap } =
    match;

  const isComp = isQueueCompetitive(queue);
  const isDeathmatch = isQueueDeathmatch(queue);
  const isTeamDeathmatch = isQueueTeamDeathmatch(queue);
  const isConsoleQueue = queue.startsWith("console_");

  const kda = (kills + assists) / (deaths || 1);
  const kpr = roundsPlayed ? kills / roundsPlayed : 0;

  const agentKey =
    allAgents
      .find((agentObj) => agentObj.uuid === match.agent.uuid)
      ?.name?.toLowerCase()
      ?.replace("/", "") || null;

  const mapName = gameMap?.name || "";

  const winStatus = getWinStatus(match);
  const win = winStatus === "win";
  const loss = winStatus === "loss";

  const matchPosition = getMatchPosition(match.match.playerMatches, match);
  const isMvp = win && matchPosition === 1;

  const highestNumPoints = getHighestNumPoints(match);
  const teamNumPoints = getHighestNumPoints(match, true, false);
  const enemyNumPoints = getHighestNumPoints(match, false, true);
  const hs = calcHeadshotPercent(match);

  const stats = [
    {
      top: isDeathmatch
        ? t("val:deathmatch.outcome", "{{kills}} - {{outcome}}", {
            kills: kills,
            outcome: highestNumPoints,
          })
        : isTeamDeathmatch
          ? t("val:teamDeathmatch.outcome", "{{teamKills}} - {{enemyKills}}", {
              teamKills: teamNumPoints,
              enemyKills: enemyNumPoints,
            })
          : t("val:nWinAndnLosses", "{{wins}} - {{losses}}", {
              wins: roundsWon,
              losses: roundsPlayed - roundsWon,
            }),
      pill: null,
      bottom: t("val:map.{{map}}", "{{map}}", {
        map: mapName,
      }),
    },
    {
      top: kda.toLocaleString(getLocale(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      pill: null,
      bottom: t("common:stats.kda", "KDA"),
    },
    {
      top: kpr.toLocaleString(getLocale(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      pill: null,
      bottom: t("common:stats.kpr", "KPR"),
    },
    {
      top: (hs / 100).toLocaleString(getLocale(), {
        style: "percent",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
      pill: null,
      bottom: t("common:stats.percentHs", "% HS"),
    },
  ];

  return (
    <MatchTileExpand
      image={{
        src: getAgentImage(agentKey),
        alt: agentKey,
      }}
      isWin={win}
      isMvp={isMvp}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={PROFILE_MATCHTILE_TABS(isDeathmatch, isTeamDeathmatch)}
      tabArgs={{
        matchId,
        profile,
        playerWeaponStats: match.playerWeaponStats,
      }}
      stats={stats}
      urlWeb={`${globals.location.origin}/valorant/match/${profileId}/${actId}/${matchId}${isConsole || isConsoleQueue ? "?console=1" : ""}`}
      urlCanonical={`/valorant/match/${profileId}/${actId}/${matchId}${isConsole || isConsoleQueue ? "?console=1" : ""}`}
      titles={[
        {
          text: isDeathmatch
            ? t("common:ordinalPlace", "{{place, ordinal}} Place", {
                place: matchPosition,
              })
            : win
              ? t("val:victory", "Victory")
              : loss
                ? t("val:defeat", "Defeat")
                : t("val:tie", "Tie"),
          bold: true,
          color: isMvp
            ? "var(--gold)"
            : win
              ? "var(--turq)"
              : loss
                ? "var(--red)"
                : "var(--shade2)",
        },
        isComp && {
          text:
            rr < 0
              ? t("val:negativeRR", "{{rr}} RR", { rr: rr })
              : rr > 0
                ? t("val:positiveRR", "+{{rr}} RR", { rr: rr })
                : t("val:rrPlaceholder", "- RR"),
          color: rr < 0 ? "var(--red)" : rr > 0 ? "var(--turq)" : null,
        },
        queue && {
          text: t(`val:queues.${queue}`, getQueueName(queue)),
        },
      ]}
      matchDate={match.match.gameStart}
      aside={<BestWeapon stats={match.playerWeaponStats} />}
      onExpand={onExpand}
    />
  );
}

export default MatchHistoryRow;
