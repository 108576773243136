import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import AgentStats from "@/game-val/AgentStats.jsx";
import SeasonReviewPromo from "@/game-val/components/SeasonReviewPromo.jsx";
import {
  DEFAULT_MATCHES_LIMIT,
  EXCLUDED_QUEUES,
  OLD_RANKS,
} from "@/game-val/constants.mjs";
import ValLiveTile from "@/game-val/LiveTile.jsx";
import MapsStats from "@/game-val/MapsStats.jsx";
import MatchHistoryHeader from "@/game-val/MatchHistoryHeader.jsx";
import MatchListRow from "@/game-val/MatchListRow.jsx";
import RankMajor from "@/game-val/RankMajor.jsx";
import {
  calcHeadshotPercent,
  getActData,
  getActiveSeason,
  getActOptions,
  getAgentImage,
  getRRChange,
  isOldRankSystem,
  isQueueDeathmatch,
} from "@/game-val/utils.mjs";
import { getPlatformPath, useConsoleState } from "@/game-val/utils/console.mjs";
import WeaponStats from "@/game-val/WeaponStats.jsx";
import {
  MatchList as SharedMatchList,
  MatchTile as SharedMatchTile,
  ShowMoreFooter,
} from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import HeadShotStats from "@/shared-fps/HeadShotStats.jsx";
import { calcRate } from "@/util/helpers.mjs";
import {
  useIsLoaded,
  useRoute,
  useTransientRoute,
} from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const updateParams = (key, value, searchParams, currentPath, transient) => {
  if (value === null) {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  updateRoute(currentPath, searchParams, {
    transient,
    offset: null,
  });
};

function ProfileOverview({ profileId, profile }) {
  const isLoaded = useIsLoaded();
  const {
    searchParams,
    currentPath,
    state: routeState,
  } = useRoute((prev, next) => {
    const prevQueue = prev.searchParams.get("queue");
    const nextQueue = next.searchParams.get("queue");

    const prevActId = prev.searchParams.get("actId");
    const nextActId = next.searchParams.get("actId");

    const prevConsole = prev.searchParams.get("console");
    const nextConsole = next.searchParams.get("console");

    return !(
      prev.currentPath === next.currentPath &&
      prevActId === nextActId &&
      prevQueue === nextQueue &&
      prevConsole === nextConsole
    );
  });
  const {
    state: { transient },
  } = useTransientRoute();
  const state = useSnapshot(readState);

  const { id: activeSeasonId } = getActiveSeason(state.val.content.acts) || {};
  const actId = searchParams.get("actId") || activeSeasonId;
  const queue = searchParams.get("queue") || "overall";
  const { isConsole } = useConsoleState();
  const platformPath = getPlatformPath(isConsole);
  const prevConsole = useRef(null);

  const [matchesShown, setMatchesShown] = useState(DEFAULT_MATCHES_LIMIT);

  const isDeathmatch = isQueueDeathmatch(queue);

  const {
    valorantProfile: {
      latestRankedRating: rankedRating,
      latestTier: latestPlayerTier,
    } = {},
    gameName,
    puuid,
  } = profile;
  const actSummaries = state.val.actSummaries[puuid];

  const valConstants = state.val.content;
  const mapMeta = state.val.meta?.maps?.list;

  const actData = getActData(valConstants);

  const latestCompetitiveRank = valConstants?.ranks.find(
    (r) => r.position === latestPlayerTier,
  )?.rank;
  const latestCompetitiveTier = valConstants?.ranks.find(
    (r) => r.position === latestPlayerTier,
  )?.tier;
  const actRanks = useMemo(() => {
    if (!valConstants?.acts || !actSummaries || actSummaries instanceof Error)
      return [];
    const seasonIdValues = getActOptions(valConstants?.acts);
    return actSummaries.map((summary) => {
      const actAndEp = seasonIdValues.find((s) => s.key === summary.act.uuid);
      const ranks = isOldRankSystem(actAndEp?.episode)
        ? OLD_RANKS
        : valConstants?.ranks;
      const topTiers = summary.topMatches
        .map((m) => m.rank)
        .sort((a, b) => a < b);
      const actRankPosition = topTiers[0];
      const actRank = ranks?.find((r) => r.position === actRankPosition);
      return {
        actName: actAndEp?.short,
        actId: actAndEp?.key,
        rankNumber: actRankPosition,
        rankKey: actRank?.key,
        tier: actRank?.tier,
        rank: actRank?.rank,
        ranks,
        nonProvisionalWins: summary.nonPlacementMatchesWon,
        topTiers: topTiers,
      };
    });
  }, [actSummaries, valConstants?.acts, valConstants?.ranks]);

  const allAgents = valConstants?.agents;
  const allWeapons = valConstants?.weapons;

  const allQueueStats =
    state.val.playerActStats[puuid]?.[actId]?.[platformPath];

  const career = useMemo(() => {
    const selectedQueueStats = [];
    if (queue !== "overall") {
      const queueStats = (
        Array.isArray(allQueueStats) ? allQueueStats : []
      ).find((s) => s.queue === queue);
      if (queueStats) selectedQueueStats.push(queueStats);
    } else if (allQueueStats?.length > 0) {
      const filteredOverallQueueStats = allQueueStats.filter(
        // excluding these queues from overall stats since
        // they are inflating them and are not really relevant
        (s) => !EXCLUDED_QUEUES.includes(s.queue),
      );
      selectedQueueStats.push(...filteredOverallQueueStats);
    }

    const stats =
      selectedQueueStats.length > 0
        ? selectedQueueStats.reduce((acc, s) => {
            acc.kills = (acc.kills || 0) + s.kills;
            acc.deaths = (acc.deaths || 0) + s.deaths;
            acc.assists = (acc.assists || 0) + s.assists;
            acc.econRating = (acc.econRating || 0) + s.econRating;
            acc.headshotPercent =
              (acc.headshotPercent || 0) +
              calcHeadshotPercent({
                headshots: s.headshots,
                bodyshots: s.bodyshots,
                legshots: s.legshots,
              });
            acc.matchesLost = (acc.matchesLost || 0) + s.matchesLost;
            acc.matchesWon = (acc.matchesWon || 0) + s.matchesWon;
            acc.matchesPlayed = (acc.matchesPlayed || 0) + s.matchesPlayed;
            acc.roundsPlayed = (acc.roundsPlayed || 0) + s.roundsPlayed;
            acc.score = (acc.score || 0) + s.score;
            acc.damagePerRound = (acc.damagePerRound || 0) + s.damagePerRound;
            return acc;
          }, {})
        : null;

    if (queue === "overall" && stats) {
      stats.headshotPercent = calcRate(
        stats.headshotPercent,
        allQueueStats.length,
        1,
      );
    }

    if (stats) {
      stats.damagePerRound = calcRate(
        stats.damagePerRound,
        stats.matchesPlayed,
        1,
      );
      stats.econRating = calcRate(stats.econRating, stats.matchesPlayed, 1);
    }

    return stats;
  }, [allQueueStats, queue]);

  let weaponStats =
    state.val.playerWeaponStats[puuid]?.[actId]?.[platformPath]?.[queue];
  weaponStats =
    weaponStats && !(weaponStats instanceof Error) ? weaponStats : null;
  let agentCareerStats =
    state.val.playerAgentStats[puuid]?.[actId]?.[platformPath]?.[queue];
  agentCareerStats =
    agentCareerStats && !(agentCareerStats instanceof Error)
      ? agentCareerStats
      : null;
  let mapCareerStats =
    state.val.playerMapStats[puuid]?.[actId]?.[platformPath]?.[queue];
  mapCareerStats =
    mapCareerStats && !(mapCareerStats instanceof Error)
      ? mapCareerStats
      : null;

  const matchList =
    state.val.playerMatches[puuid]?.[actId]?.[platformPath]?.[queue] || [];

  const sanitizedMatchList =
    matchList && !(matchList instanceof Error) ? matchList : [];

  const cleanupState = useCallback(() => {
    if (searchParams.has("queue")) {
      updateParams("queue", null, searchParams, currentPath, transient);
    }
  }, [currentPath, searchParams, transient]);

  useEffect(() => {
    // Reset state on platform change
    if (isConsole !== prevConsole.current && prevConsole.current !== null) {
      cleanupState();
    }
    prevConsole.current = isConsole;
  }, [isConsole, prevConsole, cleanupState]);

  useEffect(() => {
    const matchList =
      state.val.playerMatches[puuid]?.[actId]?.[platformPath]?.[queue];
    if (
      isLoaded &&
      actId === activeSeasonId &&
      !searchParams.get("actId") &&
      matchList &&
      matchList instanceof Array &&
      matchList.length === 0
    ) {
      const options = getActOptions(valConstants?.acts);
      if (options[1])
        updateParams(
          "actId",
          options[1].key,
          searchParams,
          currentPath,
          transient,
        );
    }
  }, [
    actId,
    activeSeasonId,
    puuid,
    queue,
    searchParams,
    state.val.playerMatches,
    valConstants?.acts,
    currentPath,
    transient,
    platformPath,
    isLoaded,
  ]);

  const isEndOfMatchList = useMemo(() => {
    const page = Math.max(
      1,
      Math.ceil(sanitizedMatchList.length / DEFAULT_MATCHES_LIMIT),
    );
    return sanitizedMatchList.length < DEFAULT_MATCHES_LIMIT * page;
  }, [sanitizedMatchList.length]);

  const rankedRatings = state.val.rankedRatingsForAct[puuid]?.[actId] || [];
  const sanitizedRankedRatings =
    rankedRatings && !(rankedRatings instanceof Error) ? rankedRatings : [];
  const ratings = sanitizedRankedRatings.reduce((acc, rr) => {
    acc[rr.gameId] = getRRChange(rr);
    return acc;
  }, {});

  const numWins = career?.matchesWon;
  const numLosses = career?.matchesLost;

  const queueData = allQueueStats
    ? {
        wins: numWins,
        losses: numLosses,
        rankedRating: rankedRating,
        rank: latestCompetitiveRank,
        tier: latestCompetitiveTier,
      }
    : null;

  const sortDate = (a, b) => b.match.gameStart - a.match.gameStart;

  const toShow = Math.min(matchesShown, sanitizedMatchList.length);
  const renderedMatchList = [...sanitizedMatchList]
    .sort(sortDate)
    .slice(0, toShow);

  let winstreak = 0;
  renderedMatchList.some((match) => {
    if (!match?.won) return true;
    winstreak += 1;
    return false;
  });

  const overallHeadshotMatchList =
    state.val.playerMatches[puuid]?.[actId]?.[platformPath]?.["headshots"];

  const { shootingDataTotals, headshotMatches } = useMemo(() => {
    const shootingDataTotals = {
      headshots: 0,
      bodyshots: 0,
      legshots: 0,
    };
    const headshotMatchList =
      queue === "overall" ? overallHeadshotMatchList : renderedMatchList;

    const matchData = [];
    if (headshotMatchList && !(headshotMatchList instanceof Error))
      for (const match of headshotMatchList) {
        if (matchData?.length >= 20) break;
        const agentKey = allAgents.find(
          (agentObj) => agentObj.uuid === match.agent.uuid,
        )?.name;
        shootingDataTotals.headshots += match.headshots;
        shootingDataTotals.bodyshots += match.bodyshots;
        shootingDataTotals.legshots += match.legshots;
        matchData.push({
          id: match.match.gameId,
          imgUrl: getAgentImage(agentKey, "matchtile") || "",
          matchDate: match.match.gameStart,
          hsPercent: calcHeadshotPercent(match),
          lastNAvgHS: calcHeadshotPercent(
            {
              headshots: match.headshotHist,
              bodyshots: match.bodyshotHist,
              legshots: match.legshotHist,
            },
            true,
            false,
          ),
        });
      }
    return { shootingDataTotals, headshotMatches: matchData };
  }, [allAgents, overallHeadshotMatchList, queue, renderedMatchList]);

  const loadMoreMatches = useCallback(() => {
    updateRoute(currentPath, searchParams, {
      transient,
      prevOffset: routeState.offset,
      offset: sanitizedMatchList?.length || 0,
    });
    setMatchesShown((prev) => prev + DEFAULT_MATCHES_LIMIT);
  }, [
    currentPath,
    routeState.offset,
    sanitizedMatchList?.length,
    searchParams,
    transient,
  ]);

  const isMatchList = Boolean(renderedMatchList?.length);

  const isOwnProfile = useMemo(() => {
    const { valorant: valAccounts } = state.settings.loggedInAccounts;
    const internalUuid = profile.valorantProfile?.internalUuid;
    return Boolean(valAccounts[internalUuid]);
  }, [state.settings.loggedInAccounts, profile]);

  return (
    <>
      <ProfileColumn className="flex column gap-sp-4 sidebar">
        {/* TODO (Rio): replace this with <ProfileRank /> */}
        <Card>
          <RankMajor
            queue={queueData}
            seasonValue={actId}
            stats={career}
            winstreak={winstreak}
            setSeason={(actId) =>
              updateParams("actId", actId, searchParams, currentPath, transient)
            }
            queueName={queue}
            setQueue={(queue) =>
              updateParams("queue", queue, searchParams, currentPath, transient)
            }
            supportedQueues={[]}
            name={gameName}
            actRanks={actRanks}
            activeSeasonId={activeSeasonId}
            actData={actData}
            isConsole={isConsole}
          />
        </Card>
        {!isDeathmatch && renderedMatchList?.length !== 0 ? (
          <Card padding="0">
            <HeadShotStats
              profileId={profileId}
              lastNStats={shootingDataTotals}
              matches={headshotMatches}
              hideHits
              showAverageHeadshots
              titleIconText={[
                "val:headshotHelp",
                "This excludes Shorty, Bucky, Judge, and Operator",
              ]}
            />
          </Card>
        ) : null}
        {!isDeathmatch && Boolean(renderedMatchList?.length) ? (
          <AgentStats agentStats={agentCareerStats} allAgents={allAgents} />
        ) : null}
        {renderedMatchList?.length && mapMeta ? (
          <MapsStats
            mapStats={mapCareerStats}
            mapMeta={mapMeta}
            isDeathmatch={isDeathmatch}
          />
        ) : null}
        {!isDeathmatch && Boolean(renderedMatchList?.length) ? (
          <WeaponStats
            allWeapons={allWeapons}
            stats={weaponStats}
            lastMatch={sanitizedMatchList[0]}
          />
        ) : null}
      </ProfileColumn>
      <ProfileColumn className="main">
        {isMatchList && (
          <MatchHistoryHeader
            matchList={renderedMatchList.slice(0, DEFAULT_MATCHES_LIMIT)}
            queueType={queue}
            allAgents={allAgents}
          />
        )}
        <ValLiveTile profileId={profileId} />
        <ProfileMatchlist>
          <SharedMatchList
            matchList={renderedMatchList ? renderedMatchList : null}
          >
            {isOwnProfile && <SeasonReviewPromo profile={profile} />}
            {renderedMatchList.map((match) => {
              const matchId = match?.match?.gameId;
              const hasMatch = match && matchId && !(match instanceof Error);

              return hasMatch ? (
                <SharedMatchTile id={matchId} key={matchId} match={match}>
                  <MatchListRow
                    match={match}
                    allAgents={allAgents}
                    rating={ratings[matchId]}
                    profileId={profileId}
                    actId={actId}
                    matchId={matchId}
                    profile={profile}
                    isConsole={isConsole}
                  />
                </SharedMatchTile>
              ) : null;
            })}
          </SharedMatchList>
          {isMatchList && (
            <ShowMoreFooter
              hasMore={!isEndOfMatchList}
              setShowMore={loadMoreMatches}
            />
          )}
        </ProfileMatchlist>
      </ProfileColumn>
    </>
  );
}

export default ProfileOverview;
