import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { accountRefs } from "@/app/account.refs.mjs";
import { appURLs } from "@/app/constants.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
// import RiotLogo from "@/inline-assets/riot-logo.svg";

const ProfilePlaceholder = styled("div")`
  display: flex;
  justify-content: center;
  height: 720px;

  background-image: url(${appURLs.CDN}/blitz/ui/img/valorant/profile/placeholder.webp);
  background-size: cover;

  ${mobile} {
    background-image: unset;

    height: unset;
  }
`;

const PrivateModal = styled("div")`
  display: flex;
  background: var(--shade7);
  padding: var(--sp-10) calc(2 * var(--sp-10));
  margin-top: var(--sp-10);
  height: fit-content;
  border-radius: var(--br-lg);

  flex-direction: column;
  text-align: center;
  gap: var(--sp-6);

  ${mobile} {
    width: 350px;
    padding: var(--sp-10) var(--sp-8);
  }

  .flex-row {
    display: flex;
    gap: var(--sp-4);
    align-items: center;
    align-self: center;

    ${mobile} {
      gap: var(--sp-2);
    }
  }
`;

const Rectangle = styled("div")`
  width: var(--sp-14);
  height: 1px;
  background: var(--shade6);

  ${mobile} {
    visibility: hidden;
  }
`;

function PrivateProfile() {
  const { isLoggedIn } = useSnapshot(accountRefs);
  // const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation();

  return (
    <ProfilePlaceholder>
      <PrivateModal className="flex column">
        <div className="flex column">
          <p className="type-h5">
            {t("valorant:profile.profileIsPrivate", "This profile is private")}
          </p>
          <p className="type-body shade1">
            {/* RSO IMPLEMENTATION COPY */}
            {!isLoggedIn
              ? t(
                  "valorant:profile.loginAndPlayMatch",
                  "Please login and play a match with this account to view stats.",
                )
              : t(
                  "valorant:profile.cannotDisplayMatches",
                  "We cannot display matches and stats for this profile.",
                )}
          </p>
        </div>
        <div className="flex-row">
          <Rectangle />
          <p className="type-caption shade3">
            {/* RSO IMPLEMENTATION COPY */}
            {/* {t(
              "valorant:profile.yoursLoginToRiot",
              "If this profile is yours, please login to your Riot account."
            )} */}

            {/* MLP COPY */}
            {t(
              "valorant:profile.yoursLoginToValorant",
              "If this account is yours, please log in and launch VALORANT.",
            )}
          </p>
          <Rectangle />
        </div>
        <div className="flex column gap-2">
          {/* RSO IMPLEMENTATION COPY */}
          {/* <Button
            emphasis="high"
            iconLeft={inProgress ? null : <RiotLogo />}
            href={inProgress ? null : appURLs.RIOT_RSO}
            onClick={() => setInProgress(true)}
            disabled={inProgress}
            target="_blank"
          >
            {!inProgress
              ? t("common:signInWithRiot", "Sign in with Riot Account")
              : t(
                  "common:verificationInProgress",
                  "Verification is in progress..."
                )}
          </Button>
          {inProgress ? (
            <Button
              emphasis="medium"
              iconLeft={<RiotLogo />}
              href={appURLs.RIOT_RSO}
              target="_blank"
            >
              {t("Re-open Riot Sign In")}
            </Button>
          ) : null} */}

          {/* The following is commented out because it's implemented wrong,
            the account sign-in needs to come from feature-a u t h */}
          {/*!isLoggedIn ? (
            <Button
              emphasis="high"
              iconLeft={<UserIcon />}
              style={{
                color: "var(--shade0)",
              }}
              href={"/account"}
            >
              {t("common:navigation.signIn", "Sign In")}
            </Button>
          ) : null*/}
          <p className="type-caption shade3">
            {/* RSO IMPLEMENTATION COPY */}
            {/* {t(
              "valorant:profile.bySigningInAcknowledgePublic",
              "By signing in you acknowledge that your profile becomes public."
            )} */}

            {/* MLP COPY */}
            {!isLoggedIn
              ? t(
                  "valorant:profile.bySigningInAcknowledgePrivate",
                  "By signing in you acknowledge that your profile remains private.",
                )
              : null}
          </p>
        </div>
      </PrivateModal>
    </ProfilePlaceholder>
  );
}

export default PrivateProfile;
