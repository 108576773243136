import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { winRatecolorRange } from "@/app/util.mjs";
import RadialProgress from "@/shared/RadialProgress.jsx";
import { calcRate } from "@/util/helpers.mjs";

const DefaultWinrate = ({ wins, games }) => {
  const { t } = useTranslation();

  const winRate = calcRate(wins, games, 2);

  const winRateColor =
    games !== 0 && winRatecolorRange((wins / (games || 1)) * 100);

  return (
    <Container>
      <RadialProgress
        size={108}
        background={"var(--shade6)"}
        data={[wins / games]}
        colors={[winRateColor]}
        strokeWidth={4}
      />
      <Center>
        <p className="type-caption">{t("common:stats.wr", "Winrate")}</p>
        <p className="type-caption--bold" style={{ color: winRateColor }}>
          {t("common:percent", "{{percent, percent}}", {
            percent: winRate,
          })}
        </p>
      </Center>
    </Container>
  );
};
export default memo(DefaultWinrate);

const Container = styled("div")`
  position: relative;
`;
const Center = styled("div")`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
