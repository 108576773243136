import Controller from "@/inline-assets/controller.svg";
import PlatformPC from "@/inline-assets/platform-pc.svg";
import PlatformPlaystation from "@/inline-assets/platform-playstation.svg";
import PlatformXbox from "@/inline-assets/platform-xbox.svg";

export interface PlatformData {
  key: string;
  display: Translation;
  icon: SVGComponent;
}

/**
 * Individual platforms, used for leaderboards/anywhere else that
 * needs to select a specific platform.
 */
export const ValorantPlatformData = [
  {
    key: "pc",
    display: ["common:platform.pc", "PC"],
    icon: PlatformPC,
  },
  {
    key: "playstation",
    display: ["common:platform.playstation", "PlayStation"],
    icon: PlatformPlaystation,
  },
  {
    key: "xbox",
    display: ["common:platform.xbox", "Xbox"],
    icon: PlatformXbox,
  },
] as const satisfies PlatformData[];

/**
 * More broadly just PC/Console. Used for matches, and places that
 * don't need to know the specific platform. Treated as a boolean.
 */
export const ValorantConsoleData = [
  {
    key: "0",
    display: ["common:platform.pc", "PC"],
    icon: PlatformPC,
  },
  {
    key: "1",
    display: ["common:platform.console", "Console"],
    icon: Controller,
  },
] as const satisfies PlatformData[];
