import React from "react";
import { useTranslation } from "react-i18next";

import { calcHeadshotPercent, getWeaponImage } from "@/game-val/utils.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { getWeaponHeadshotColor } from "@/shared-fps/utils.mjs";
import { calcRate } from "@/util/helpers.mjs";

const getIds = (weapons) => {
  const ids = {};
  for (const weapon of weapons) {
    if (weapon.name.toLowerCase() !== "headhunter")
      ids[weapon.uuid] = weapon.name;
  }
  return ids;
};

const getWeaponName = (id, allWeapons) => {
  const foundWeapon = allWeapons.find(
    (weapon) => weapon.uuid === id.replace("_alt", ""),
  );
  return foundWeapon?.name;
};

const WeaponStats = ({ allWeapons, stats, lastMatch }) => {
  const { t } = useTranslation();
  const WEAPON_IDs = allWeapons ? getIds(allWeapons) : null;
  const last20HeadshotPercent = lastMatch
    ? calcHeadshotPercent(
        {
          headshots: lastMatch.headshotHist,
          bodyshots: lastMatch.bodyshotHist,
          legshots: lastMatch.legshotHist,
        },
        true,
        false,
      )
    : null;

  const renderedList = (stats || []).filter((wep) =>
    getWeaponImage(wep.weapon.uuid, WEAPON_IDs),
  );

  return (
    <ListItemsTruncated
      title={t("common:weapons", "Weapons")}
      list={renderedList}
      itemLeftContent={(item) => {
        const weaponId = item.weapon.uuid;
        const weaponName = getWeaponName(weaponId, allWeapons);
        const weaponImage = getWeaponImage(weaponId, WEAPON_IDs);
        if (!weaponImage) return null;
        return (
          <>
            <img
              width="93"
              height="30"
              src={weaponImage}
              loading="lazy"
              alt={weaponName}
            />
            <div className="type-callout--bold map-name">
              {t("val:weaponName", "{{weaponName}}", {
                weaponName: weaponName,
              })}
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const kills = item.kills;
        const deaths = item.deaths;
        const kd = calcRate(kills, deaths, 1);
        const headshotPercent = calcHeadshotPercent(
          {
            headshots: item.headshots,
            bodyshots: item.bodyshots,
            legshots: item.legshots,
          },
          true,
          false,
        );

        return (
          <>
            <div className="type-caption--bold">
              <span>{t("val:kd", "{{kd}} KD", { kd: kd })}</span>
            </div>
            <div
              style={{
                color: getWeaponHeadshotColor(
                  headshotPercent,
                  last20HeadshotPercent,
                ),
              }}
              className="type-caption headshot-percent"
            >
              <span>
                {t("common:stats.valueHsPercent", "{{hsPercent, percent}} HS", {
                  hsPercent: headshotPercent / 100,
                })}
              </span>
            </div>
          </>
        );
      }}
    />
  );
};

export default WeaponStats;
