import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BadgeContainer,
  cssTooltipContainerWithArrow,
  WIN_COLOR,
} from "@/game-val/WinStreakBadge.style.jsx";
import LoseStreak from "@/inline-assets/streak-lose.svg";
import WinStreak from "@/inline-assets/streak-win.svg";

const Badge = ({
  streakType,
  streakCount,
  hideBackground,
  className,
  showTooltip,
  onMouseOut,
  onMouseOver,
}) => {
  const { t } = useTranslation();
  return (
    <BadgeContainer
      style={{
        background:
          !hideBackground &&
          (streakType === "win"
            ? "rgba(255, 148, 23, 0.1)"
            : "rgba(73, 180, 255, 0.15)"),
      }}
      className={className}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {streakType === "win" ? <WinStreak fill="#FF9417" /> : <LoseStreak />}
      <p
        className="type-caption--bold"
        style={{
          color: streakType === "win" ? "#ff9417" : "#49B4FF",
          marginLeft: "var(--sp-1)",
        }}
      >
        {streakCount}
      </p>
      <div></div>
      <span className="bub-1"></span>
      <span className="bub-2"></span>
      <span className="bub-3"></span>
      <span className="bub-4"></span>
      <span className="bub-5"></span>
      <span className="bub-6"></span>
      <span className="bub-7"></span>
      <span className="bub-8"></span>
      <span className="bub-9"></span>
      <span className="bub-10"></span>
      {showTooltip && (
        <div className={cssTooltipContainerWithArrow()}>
          <WinStreak fill="#FF9417" />
          &nbsp;
          <p style={{ textAlign: "center", color: WIN_COLOR }}>{streakCount}</p>
          &nbsp;
          <p>{t("val:streak", "Win Streak")}</p>
        </div>
      )}
    </BadgeContainer>
  );
};

const ValBadge = ({ streakCount, streakType }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Badge
      streakType={streakType}
      streakCount={streakCount}
      showTooltip={showTooltip}
      onMouseOver={() => {
        setShowTooltip(true);
      }}
      onMouseOut={() => {
        setShowTooltip(false);
      }}
    />
  );
};

const ValWinStreakBadge = memo(ValBadge);

export { ValWinStreakBadge };
