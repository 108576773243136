import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ConsoleSelector from "@/game-val/components/ConsoleSelector.jsx";
import PrivateProfile from "@/game-val/PrivateProfile.jsx";
import { isPrivateProfile } from "@/game-val/profile-utils.mjs";
import ProfileAgents from "@/game-val/ProfileAgents.jsx";
import ProfileOverview from "@/game-val/ProfileOverview.jsx";
import ProfileWeapons from "@/game-val/ProfileWeapons.jsx";
import { getProfileIcon } from "@/game-val/static.mjs";
import { useConsoleState } from "@/game-val/utils/console.mjs";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile, { UnknownPlayerHeader } from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import ProfileEmpty from "@/shared/ProfileEmpty.jsx";
import RiotIDHeaderTitle from "@/shared/RiotIDHeaderTitle.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Profile(props) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const profiles = state.val.profiles; // {}

  const {
    parameters: [profileIdParam, selectedTab],
  } = useRoute((prev, next) => {
    const prevAct = prev.searchParams.get("actName");
    const nextAct = next.searchParams.get("actName");
    return prev.currentPath === next.currentPath && prevAct === nextAct;
  });
  const { isConsole } = useConsoleState();
  const profileId = profileIdParam ?? `${props.gameName}-${props.tagLine}`;
  const baseUrl = `/valorant/profile/${profileId}`;

  const lowercaseName = profileId?.toLowerCase?.();
  const profile = profiles[lowercaseName] ?? {};
  const profileError = profile instanceof Error ? profile : !profileId;
  const isPrivate = isPrivateProfile(profile);
  const isPermitted = !isPrivate;

  const tabs = useMemo(() => {
    return [
      {
        name: `${t("val:navigation.overview", "Overview")}`,
        id: "overview",
        url: `${baseUrl}${isConsole ? "?console=1" : ""}`,
        routeMatch: new RegExp(`^${baseUrl}(?:\\?.*|$)`, "i"),
        isNew: false,
      },
      {
        name: `${t("val:navigation.agents", "Agents")}`,
        id: "agents",
        url: `${baseUrl}/agents${isConsole ? "?console=1" : ""}`,
        routeMatch: new RegExp(`^${baseUrl}/agents(?:\\?|$)`, "i"),
        isNew: false,
        disabled: !isPermitted,
      },
      {
        name: `${t("val:navigation.weapons", "Weapons")}`,
        id: "weapons",
        url: `${baseUrl}/weapons${isConsole ? "?console=1" : ""}`,
        routeMatch: new RegExp(`^${baseUrl}/weapons(?:\\?|$)`, "i"),
        isNew: false,
        disabled: !isPermitted,
      },
    ];
  }, [baseUrl, isPermitted, t, isConsole]);

  const {
    gameName,
    tagLine,
    puuid,
    valorantProfile: { level, playerCardId, lastAgent } = {},
  } = profile && !profileError ? profile : {};

  const links = tabs.map((tab) => ({
    url: tab.url,
    text: tab.name,
    disabled: tab.disabled,
    routeMatch: tab.routeMatch,
  }));

  return (
    <ProfileLayout>
      {!profileError ? (
        <PageHeader
          imageRound
          title={RiotIDHeaderTitle({ gameName, tagLine })}
          underTitle={<ConsoleSelector placement="profile" />}
          image={getProfileIcon(playerCardId, lastAgent?.name)}
          accentText={level}
          style={{ gridArea: "header" }}
          links={links}
        />
      ) : (
        <UnknownPlayerHeader />
      )}
      <Container>
        {isPermitted ? (
          <SharedProfile>
            {!selectedTab ? (
              profileIdParam || props.gameName ? (
                <ProfileOverview profileId={profileId} profile={profile} />
              ) : (
                <ProfileEmpty />
              )
            ) : null}
            {selectedTab === "agents" ? <ProfileAgents puuid={puuid} /> : null}
            {selectedTab === "weapons" ? (
              <ProfileWeapons puuid={puuid} />
            ) : null}
          </SharedProfile>
        ) : (
          <PrivateProfile />
        )}
      </Container>
    </ProfileLayout>
  );
}

export function meta(params) {
  const user = params[0].replace("-", " #");

  return {
    title: [
      "val:meta.profile.title",
      "{{user}}'s VALORANT Performance Overview",
      { user: user },
    ],
    description: [
      "val:meta.profile.description",
      "View {{user}}'s VALORANT profile and see how they perform.",
      { user: user },
    ],
  };
}

export default Profile;
