import React from "react";
import { useTranslation } from "react-i18next";

import { AgentImage } from "@/game-val/CommonComponent.jsx";
import { calcAvgScore, getAgentImage, kdaColor } from "@/game-val/utils.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { calcRate } from "@/util/helpers.mjs";

const AgentStats = ({ agentStats, allAgents }) => {
  const { t } = useTranslation();

  const renderedList = agentStats
    ? agentStats.sort((a, b) => b.matchesPlayed - a.matchesPlayed)
    : null;

  if (!agentStats) return null;

  return (
    <ListItemsTruncated
      title={t("common:agents", "Agents")}
      list={renderedList}
      itemLeftContent={(item) => {
        const agentId = item.agent.uuid;
        const agentName =
          allAgents.find((agentObj) => agentObj.uuid === agentId)?.name || "";
        const agentKey = allAgents
          .find((agentObj) => agentObj.uuid === agentId)
          ?.name?.toLowerCase()
          ?.replace("/", "");

        const { score, roundsPlayed } = item;

        return (
          <>
            <AgentImage
              src={getAgentImage(agentKey)}
              style={{ width: "var(--sp-10)" }}
              alt={agentName}
            />
            <div>
              <p className="type-callout--bold name">
                {t("val:agentName", "{{agentName}}", { agentName })}
              </p>
              <p className="type-caption--semi sub-stat shade2">
                {t("val:combatScore", "{{combatScore}} Combat Score", {
                  combatScore: calcAvgScore(score, roundsPlayed),
                })}
              </p>
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const {
          matchesWon: wins,
          matchesPlayed: matches,
          matchesLost: losses,
          kills,
          deaths,
        } = item;

        const kd = calcRate(kills, deaths, 2);
        const ties = matches - losses - wins;
        const winRate = calcRate(wins, matches - ties, 2);

        return (
          <>
            <div className="type-caption--bold">
              <span className="win-loss">
                {t("common:stats.winsDashLosses", "{{wins}}W - {{losses}}L", {
                  wins: wins,
                  losses: losses,
                })}
              </span>
              &nbsp;
              <span className="win-loss-rate">
                {t("common:percent", "{{percent, percent}}", {
                  percent: winRate,
                })}
              </span>
            </div>
            <p
              className="type-caption sub-stat match-kd"
              style={{ color: kdaColor(kd) }}
            >
              {t("val:kd", "{{kd}} KD", { kd: kd })}
            </p>
          </>
        );
      }}
    />
  );
};

export default AgentStats;
